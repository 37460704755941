import { render, staticRenderFns } from "./brandDetails.vue?vue&type=template&id=4a4ea668&scoped=true&"
import script from "./brandDetails.vue?vue&type=script&lang=js&"
export * from "./brandDetails.vue?vue&type=script&lang=js&"
import style0 from "./brandDetails.vue?vue&type=style&index=0&id=4a4ea668&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a4ea668",
  null
  
)

export default component.exports